import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    can(key) {
      // Check if the user has the specific permission key
      return this.userPermissions.includes(key)
    },
    removeActionsHeader() {
      // Remove the last element from the headers array
      this.headers.pop()
    },
  },
}
