<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="280"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="60px"
          max-width="60px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ companyName }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <template v-for="(item, index) in navigationItems">
        <template v-if="item.children">
          <nav-menu-group
            :key="item.title"
            :title="item.title"
            :icon="item.icon"
          >
            <template v-for="child in item.children">
              <nav-menu-link
                :key="child.title"
                class="ml-4"
                :title="child.title"
                :to="child.to"
                :icon="child.icon"
              ></nav-menu-link>
            </template>
          </nav-menu-group>
        </template>
        <template v-else>
          <nav-menu-link
            :key="item.title"
            :title="item.title"
            :to="item.to"
            :icon="item.icon"
          ></nav-menu-link>
        </template>
      </template>
    </v-list>
    <nav-menu-link
      title="Settings"
      :to="{ name: 'settings' }"
      :icon="icons.mdiCog"
      class="settings-menu"
    ></nav-menu-link>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAccountGroup,
  mdiEyeOutline,
  mdiReceipt,
  mdiTable,
  mdiChatOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiHomeGroup,
  mdiWeb,
  mdiCurrencyUsdCircleOutline,
  mdiCurrencyUsdOff,
  mdiRouterNetwork,
  mdiNetworkOutline,
  mdiIp,
  mdiIpNetwork,
  mdiWan,
  mdiCog,
  mdiPoll,
  mdiMinus,
  mdiTimeline,
  mdiArrowSync,
  mdiCashMultiple,
  mdiAccessPoint,
  mdiCloudUpload,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  mixins: [hasPermission],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      navigationItems: [
        {
          title: 'Dashboard',
          to: { name: 'dashboard' },
          icon: mdiHomeOutline,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['companyName']),
  },
  mounted() {
    this.setOptionalNavigationItems()
  },
  methods: {
    setOptionalNavigationItems() {
      if (this.can('station-list')) {
        this.navigationItems.push({
          title: 'Stations',
          to: { name: 'stations' },
          icon: mdiHomeGroup,
        })
      }
      if (this.can('router-list') || this.can('ip-pool-list')) {
        const networkingMenu = {
          title: 'Networking',
          icon: mdiNetworkOutline,
          children: [],
        }

        // Add child items to the Networking menu based on permissions
        if (this.can('router-list')) {
          networkingMenu.children.push({
            title: 'Routers',
            to: { name: 'routers' },
            icon: mdiRouterNetwork,
          })
        }

        if (this.can('ip-network-list')) {
          networkingMenu.children.push({
            title: 'IP Networks',
            to: { name: 'ip-networks' },
            icon: mdiIpNetwork,
          })
        }

        if (this.can('ip-pool-list')) {
          networkingMenu.children.push({
            title: 'IP Pools',
            to: { name: 'ip-pools' },
            icon: mdiIp,
          })
        }

        // Finally, add the Networking menu to the navigation items
        this.navigationItems.push(networkingMenu)
      }
      if (this.can('internet-plan-list')) {
        const internetPlansMenu = {
          title: 'Internet Plans',
          icon: mdiWeb,
          children: [],
        }

        // Add child items to the Internet Plans menu based on permissions
        if (this.can('internet-plan-list')) {
          internetPlansMenu.children.push({
            title: 'PPPOE Plans',
            to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionTypeEnum.PPPOE } },
            icon: mdiWan,
          })
        }
        if (this.can('internet-plan-list')) {
          internetPlansMenu.children.push({
            title: 'Static Ip Plans',
            to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionTypeEnum.STATIC } },
            icon: mdiMinus,
          })
        }
        if (this.can('internet-plan-list')) {
          internetPlansMenu.children.push({
            title: 'Dynamic IP Plans',
            to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionTypeEnum.DYNAMIC } },
            icon: mdiArrowSync,
          })
        }
        if (this.can('internet-plan-list')) {
          internetPlansMenu.children.push({
            title: 'Hotspot Plans',
            to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionTypeEnum.HOTSPOT } },
            icon: mdiAccessPoint,
          })
        }

        // Finally, add the Internet Plans menu to the navigation items
        this.navigationItems.push(internetPlansMenu)
      }
      if (this.can('customer-list')) {
        this.navigationItems.push({
          title: 'Customers',
          to: { name: 'customers' },
          icon: mdiAccountGroup,
        })
      }
      if (this.can('payment-list')) {
        const financeMenu = {
          title: 'Finance',
          icon: mdiCashMultiple,
          children: [
            {
              title: 'Invoices',
              to: { name: 'invoices' },
              icon: mdiReceipt,
            },
            {
              title: 'Payments',
              to: { name: 'payments' },
              icon: mdiCurrencyUsdCircleOutline,
            },
            {
              title: 'Unresolved Payments',
              to: { name: 'unresolved-payments' },
              icon: mdiCurrencyUsdOff,
            },
          ],
        }
        this.navigationItems.push(financeMenu)
      }
      if (this.can('sms-list')) {
        this.navigationItems.push({
          title: 'SMS',
          to: { name: 'sms' },
          icon: mdiChatOutline,
        })
      }
      if (this.can('router-backup-list')) {
        this.navigationItems.push({
          title: 'Backups',
          to: { name: 'backups' },
          icon: mdiCloudUpload,
        })
      }
      if (this.can('activity-log-list')) {
        this.navigationItems.push({
          title: 'Activity Logs',
          to: { name: 'activity-logs' },
          icon: mdiTimeline,
        })
      }
      if (this.can('analytics-list')) {
        this.navigationItems.push({
          title: 'Analytics',
          icon: mdiPoll,
          children: [
            {
              title: 'Internet Plans',
              to: { name: 'internet-plan-analytics' },
              icon: mdiWeb,
            },
            {
              title: 'Customers',
              to: { name: 'customer-analytics' },
              icon: mdiAccountGroup,
            },
            {
              title: 'Payments',
              to: { name: 'payment-analytics' },
              icon: mdiCurrencyUsdCircleOutline,
            },
            {
              title: 'Sms',
              to: { name: 'sms-analytics' },
              icon: mdiChatOutline,
            },
          ],
        })
      }
    },
  },
  setup() {
    return {
      internetConnectionTypeEnum,
      icons: {
        mdiHomeOutline,
        mdiAccountGroup,
        mdiEyeOutline,
        mdiReceipt,
        mdiTable,
        mdiChatOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiHomeGroup,
        mdiWeb,
        mdiCurrencyUsdCircleOutline,
        mdiRouterNetwork,
        mdiNetworkOutline,
        mdiIp,
        mdiIpNetwork,
        mdiWan,
        mdiCog,
        mdiPoll,
        mdiMinus,
        mdiTimeline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list {
    margin-bottom: 48px; /* Adjust the value as needed to create space between menu items and the Settings menu */
  }
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
  .settings-menu {
    position: absolute;
    bottom: 0; /* Adjust the value as needed */
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: var(--v-aura-base);
    z-index: 1; /* Ensure it appears above other menu items */
  }
}
</style>
